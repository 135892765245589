<template>
  <div class="container-fuild">
    <div
      class="row"
      style="padding: 1.5rem;"
    >
      <div class="card col-12 col-sm-10 col-xl-9">
        <div class="card-body">
          <div class="mb-3">
            <h3>
              โอนย้าย
            </h3>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-sm-6 col-xl-5 text-center mb-2">
              <b-img
                thumbnail
                fluid
                :src="pic"
                alt="Image 1"
                style="max-width:209px;width:100%"
                @error="setAltImg"
              />
            </div>
            <div class="col-12 col-sm-6 col-xl-7 ">
              <table>
                <thead>
                  <tr>
                    <th style="min-width:120px; width:30%;" />
                    <th style="width:70%;" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="pr-1">
                      <p>ชื่อสินค้า</p>
                    </td>
                    <td>
                      <p>{{ name }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      <p>จำนวนคงเหลือ</p>
                    </td>
                    <td>
                      <div class="d-flex">
                        <p class="mr-2">
                          {{ sumStock }}
                        </p>
                        <p>ชิ้น</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      <p style="margin-top:11px">
                        จำนวน
                      </p>
                    </td>
                    <td>
                      <b-input-group class="setSpinbutton">
                        <b-input-group-prepend>
                          <b-button
                            variant="outline-secondary"
                            class="py-0"
                            size="sm"
                            style="width: 35px;
  border: 1px solid #d8d6de !important;
      color: white;
    background-color: #EA5354;"
                            @click="valueAmountChange(1)"
                          >
                            <b-icon
                              icon="dash"
                              font-scale="1.6"
                              style="margin-left: -8px;"
                            />
                          </b-button>
                        </b-input-group-prepend>
                        <VueNumberFormat
                          v-model="input.listProduct.amount"
                          class="text-center"
                          style="padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
        position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;"
                        />

                        <!-- <b-form-input
                          v-model="input.amount"
                          type="number"
                          min="0"
                          max="10000"
                          class="border-secondary text-center"
                          style="padding: 0 !important;border: 1px solid #d8d6de !important;"
                          number
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          :readonly="statusPage==='แก้ไข'? true:false"
                        /> -->
                        <b-input-group-append>
                          <b-button
                            variant="outline-dark"
                            class="py-0"
                            size="sm"
                            style="width: 35px;
  border: 1px solid #d8d6de !important;
      color: white;
    background-color: #29C76F;"
                            @click="valueAmountChange(-1)"
                          >
                            <b-icon
                              icon="plus"
                              font-scale="1.6"
                              style="margin-left: -8px;"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                  <tr /><tr>
                    <td />
                    <td colspan="2"> <div class="d-flex justify-content-center">
                      <b-button
                        variant="outline-dark"
                        class="border-primary"
                        size="sm"
                        style="width: 35px;
    padding: 0px;
    height: 25px;margin-right: 3px;"
                        @click="onClickAddAmount(10)"
                      >10
                      </b-button>
                      <b-button
                        variant="outline-dark"
                        class="border-primary"
                        size="sm"
                        style="width: 35px;
    padding: 0px;
    height: 25px;margin-right: 3px;"
                        @click="onClickAddAmount(50)"
                      >50
                      </b-button>
                      <b-button
                        variant="outline-dark"
                        class="border-primary"
                        size="sm"
                        style="width: 35px;
    padding: 0px;
    height: 25px;"
                        @click="onClickAddAmount(100)"
                      >100
                      </b-button>

                    </div></td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      <p style="margin-top:11px">
                        คลังต้นทาง
                      </p>
                    </td>
                    <td>
                      <b-form-select
                        v-model="input.listProduct.warehouseId"
                        :options="warehuseOrigin"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="pr-1">
                      <p style="margin-top:11px">
                        คลังปลายทาง
                      </p>
                    </td>
                    <td>
                      <b-form-select
                        v-model="input.endWarehouse"
                        :options="warehuseDestination"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mb-1 d-flex justify-content-center">
            <b-button
              variant="primary"
              class="mr-1"
              style="    width: 100px;"
              @click="onClickCommit()"
            >
              ยืนยัน
            </b-button>
            <b-button
              variant="warning"
              class="mr-1"
              style="    width: 100px;"
              @click="onClickReturnPage()"
            >
              ย้อนกลับ
            </b-button>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import { BImg, BButton, BFormInput, BFormSpinbutton, BFormSelect, BInputGroupPrepend, BInputGroup, BInputGroupAppend, BIcon, BIconDash, BIconPlus } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import PlaceHolder from '@/assets/images/production/img_default.png'

export default defineComponent({
  name: 'product_tranfer',
  components: {
    BImg,
    BButton,
    BFormInput,
    BFormSpinbutton,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BInputGroupAppend,
    BIcon,
    BIconDash,
    BIconPlus

  },
  computed: {

  },
  watch: {
    dataStockOnload (val) {
      if (val.length === 0) {
        this.sumStock = 0
        this.input.listProduct.warehouseId = this.warehuseOrigin[0].value
      } else {
        this.sumStock = val[0].amount.$numberDecimal * 1
        this.input.listProduct.warehouseId = val[0].warehouseId
        if (this.$route.params.whs) this.input.endWarehouse = this.$route.params.whs
        else { this.input.endWarehouse = val[0].warehouseId }
      }
    },
    'input.listProduct.warehouseId': {
      handler (val) {
        if (!this.onLoad) {
          this.onLoad = true
          return
        }
        this.loadData(this.input.listProduct.id, val)
      }
    },
    'input.listProduct.amount': {
      handler (val) {
        if (val >= this.sumStock) this.input.listProduct.amount = this.sumStock
      }
    }
  },
  methods: {
    onClickCommit () {
      if (this.input.listProduct.warehouseId === this.input.endWarehouse) {
        this.$swal.fire({
          icon: 'info',
          text: 'โปรดตรวจสอบคลังต้นทางเเละปลายทาง'
        })
        return
      }
      if (this.input.listProduct.amount === 0) {
        this.$swal.fire({
          icon: 'info',
          text: 'โปรดตรวจสอบจำนวน'
        })
        return
      }
      this.$store.dispatch('tranfer/Addtranfer', this.input).then(result => {
        if (result.data.success) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'success',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: result.data.message
            }
          })
          if (this.$route.params.whs) this.onClickReturnPage()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: result.data.message
            }
          })
        }
      })
    },
    onClickReturnPage () {
      this.$router.go(-1)
    },
    onClickAddAmount (val) {
      this.input.listProduct.amount += val
    },
    valueAmountChange (val) {
      if (this.input.listProduct.amount >= 0) {
        this.input.listProduct.amount -= val
        if (this.input.listProduct.amount <= 0) this.input.listProduct.amount = 0
      }
    },
    loadWareHouse () {
      this.$store.dispatch('warehouse/getWareHouse', this.input).then(result => {
        this.warehuseOrigin = []
        this.warehuseDestination = []
        result.data.items.forEach(data => {
          this.warehuseOrigin.push({ value: data._id, text: data.name })
        })
        this.warehuseDestination = this.warehuseOrigin
      })
    },
    loadData (productId, warehouseId) {
      this.$store.dispatch('stock/getStock', { productId, warehouseId }).then(result => {
        this.dataStockOnload = result.data.items
      })
    },
    loader () {
      this.loadWareHouse()
      this.$store.dispatch('production/getProduct', { code: this.$route.params.code }).then(result => {
        if (result.data.items.length > 0) {
          const data = result.data.items[0]
          this.input.listProduct.id = data._id
          this.name = data.name
          this.pic = this.$baseURL + data.pic
          // this.input.amount = parseFloat(data.amount.$numberDecimal)
          this.loadData(this.input.listProduct.id, '')
        }
      })
    },
    setAltImg (e) {
      console.log('alt')
      e.target.src = PlaceHolder
    }
  },
  created () {
    this.loader()
  },
  data () {
    return {
      dataStockOnload: [],
      onLoad: false,
      sumStock: 0,
      ID: '',
      name: '',
      input: {
        amount: 0,
        listProduct: {
          id: '',
          amount: 0,
          warehouseId: ''
        },
        endWarehouse: '',
        employerId: JSON.parse(localStorage.getItem('userData')).id

      },
      pic: '',
      warehuseOrigin: [],
      warehuseDestination: [],

      Quantity: 1,
      Origin: '',
      destination: '',
      options: [
        { value: '', text: 'Please select Store' },
        { value: 'a', text: 'บ บางบ่อ' },
        { value: 'b', text: 'บ บางแสน' },
        { value: 'c', text: 'บ บางประกง' },
        { value: 'd', text: 'บ บางปู', disabled: true }
      ]
    }
  }
})
</script>
